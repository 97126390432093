import React, { useState, useEffect } from 'react';
import TextField from '@mui/material/TextField';
import { DataGrid } from '@mui/x-data-grid';

import '../App.scss';
import 'boxicons/css/boxicons.min.css';

const Evenements = () => {

    const [orundumActuel, setorundumActuel] = useState(0);
    const [opActuel, setOpActuel] = useState(0);
    const [ticketsActuel, setTicketsActuel] = useState(0);
    const [pullsActuel, setPullsActuel] = useState(0);
    const [goldCertsActuel, setGoldCertsActuel] = useState(0);

    const [orundumAfter, setorundumAfter] = useState(0);
    const [opAfter, setOpAfter] = useState(0);
    const [ticketsAfter, setTicketsAfter] = useState(0);
    const [pullsAfter, setPullsAfter] = useState(0);
    const [goldCertsAfter, setGoldCertsAfter] = useState(0);


    const [daysBefore, setDaysBefore] = useState(0);

    const [selectedRows, setSelectedRows] = useState([]);



    const columns = [
        { field: 'nom', headerName: 'Nom', width: 250, disableColumnMenu: true, sortable: false },
        { field: 'oui_non', headerName: 'Oui/Non', width: 100, disableColumnMenu: true, sortable: false },
        { field: 'orundum', headerName: 'orundum', width: 150, disableColumnMenu: true, sortable: false },
        { field: 'op', headerName: 'OP', width: 150, disableColumnMenu: true, sortable: false },
        { field: 'valeurHT', headerName: 'ValeurH.T.', width: 150, disableColumnMenu: true, sortable: false }
    ];

    const rows = [
        { id: 1, nom: 'Pack mensuel Orundum', oui_non: 'oui', orundum: 200, op: 0, valeurHT: '$4.99' },
        { id: 2, nom: 'Pack Mensuel OP', oui_non: 'oui', orundum: 0, op: 42, valeurHT: '$25.99' },
      ];

    // for Actuel
    const handleorundumChange = (event) => {
        setorundumActuel(event.target.value); // Mettez à jour la variable d'état avec la nouvelle valeur de l'input
        setPulls();
    };

    const handleOpChange = (event) => {
        setOpActuel(event.target.value); // Mettez à jour la variable d'état avec la nouvelle valeur de l'input
        setPulls();
    };

    const handleTicketsChange = (event) => {
        setTicketsActuel(event.target.value); // Mettez à jour la variable d'état avec la nouvelle valeur de l'input
        setPulls();
    };

    const handleGoldChange = (event) => {
        setGoldCertsActuel(event.target.value); // Mettez à jour la variable d'état avec la nouvelle valeur de l'input
        setPulls();
    };

    // for after
    const handleOrundumAfterChange = () => {
        const newOnutrum = new Date("11/02/2023");
        const today = new Date();
        today.setHours(0, 0, 0, 0);
        const diff = newOnutrum - today;
        setDaysBefore(Math.floor(diff / (1000 * 60 * 60 * 24)))
    }

    useEffect(() => {
        const fetchData = async () => {
          try {
            handleOrundumAfterChange();
          } catch (error) {
            console.error('Erreur lors de la récupération des données Firestore : ', error);
          }
        };

        fetchData();
    }, []);

  

  const setPulls = () => {
    const one = orundumActuel / 600;
    const two = (opActuel * 180) / 600;
    const three = one + two;
    const four = three + parseInt(ticketsActuel);
    setPullsActuel(four)
  }

  

  return (
    <main className='App m-5'>
        <h1 style={{textAlign: 'center'}}>Evenements</h1>
      
        <br />

        <h3>Inventaire actuel</h3>

        <br />

        <div style={{ textAlign: 'center' }}>
            <TextField
                label="orundum"
                onChange={handleorundumChange}
                style={{ margin: 10, width: '30%' }}
                value={orundumActuel}
                type="number"
                placeholder="Mettre le nom de l'item"
            />
            <TextField
                label="OP"
                onChange={handleOpChange}
                style={{ margin: 10, width: '30%' }}
                value={opActuel}
                type="number"
                placeholder="Mettre le nombre d'item"
            />
            <TextField
                label="Tickets"
                onChange={handleTicketsChange}
                style={{ margin: 10, width: '30%' }}
                value={ticketsActuel}
                type="number"
                placeholder="Mettre le nombre d'item"
            />

            <TextField
                label="Pulls"
                style={{ margin: 10, width: '40%' }}
                value={pullsActuel}
                disabled={true}
                placeholder="Mettre le nombre d'item"
            />

            <TextField
                label="Gold"
                onChange={handleGoldChange}
                style={{ margin: 10, width: '40%' }}
                value={goldCertsActuel}
                type="number"
                placeholder="Mettre le nombre d'item"
            />
        </div>

        <br />

        <h3>Inventaire calculé jusqu'à l'event</h3>

        <br />

        <div style={{ textAlign: 'center' }}>
            <TextField
                label="orundum"
                onChange={handleOrundumAfterChange}
                style={{ margin: 10, width: '30%' }}
                value={orundumAfter}
                type="number"
                disabled={true}
                placeholder="Mettre le nom de l'item"
            />
            <TextField
                label="OP"
                style={{ margin: 10, width: '30%' }}
                value={opAfter}
                type="number"
                placeholder="Mettre le nombre d'item"
            />
            <TextField
                label="Tickets"
                style={{ margin: 10, width: '30%' }}
                value={ticketsAfter}
                type="number"
                placeholder="Mettre le nombre d'item"
            />

            <TextField
                label="Pulls"
                style={{ margin: 10, width: '40%' }}
                value={pullsAfter}
                placeholder="Mettre le nombre d'item"
            />

            <TextField
                label="Gold"
                style={{ margin: 10, width: '40%' }}
                value={goldCertsAfter}
                type="number"
                placeholder="Mettre le nombre d'item"
            />
        </div>

        <br />

        <div>
        <TextField
            label="Jours avant l'event"
            style={{ margin: 10, width: '30%' }}
            value={daysBefore}
            type="number"
            disabled={true}
            placeholder="Mettre le nombre d'item"
        />
        </div>

        <h3 style={{textAlign: 'center'}}>Pack mensuels</h3>

        <br />

        <div style={{ textAlign: 'center' }}>
            <div style={{ width: '70%', margin: '0 auto' }}>
                <DataGrid
                    rows={rows}
                    columns={columns}
                    checkboxSelection
                    disableColumnFilter
                    hideFooter={true}
                    onRowSelectionModelChange={itm => console.log(itm)}
                    // onRowSelectionModelChange={(ids) => {
                    //     const selectedIDs = new Set(ids);
                    //     const selectedRows = rows.filter((row) =>
                    //       selectedIDs.has(row.id),
                    //     );
              
                    //     setSelectedRows(selectedRows);
                    //   }}
                />
            </div>

            {/* test {selectedRows} */}
        </div>

        <br />

        <h3 style={{textAlign: 'center'}}>Pack OP</h3>

        <br />

        <div style={{ textAlign: 'center' }}>
            <div style={{ width: '70%', margin: '0 auto' }}>
                <DataGrid
                    rows={rows}
                    columns={columns}
                    checkboxSelection
                    disableColumnFilter
                    hideFooter={true}
                />
            </div>
        </div>
    </main>
  );
}

export default Evenements;