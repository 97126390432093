import React, { useState, useEffect } from 'react';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import CloseIcon from '@mui/icons-material/Close';
import { Bars3Icon, BellIcon, XMarkIcon } from '@heroicons/react/24/outline'


import { firestore } from '../firebase';

import { addDoc, collection, getDocs, deleteDoc } from 'firebase/firestore';

import '../App.scss';
import 'boxicons/css/boxicons.min.css';

const Outils = () => {

  // liste des item
  const [array, setArray] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const ref = collection(firestore, 'ArrayItem');
        const querySnapshot = await getDocs(ref);
        const data = querySnapshot.docs.map((doc) => doc.data());
        data.sort((a, b) => a.id - b.id) // order by

        setArray(data);
      } catch (error) {
        console.error('Erreur lors de la récupération des données Firestore : ', error);
      }
    };

    fetchData();
  }, []);

  const handleTitleChange = (e, index) => {
    const updatedArray = [...array];
    updatedArray[index].title = e.target.value;
    setArray(updatedArray);
  };

  const handleNumberChange = (e, index) => {
    const updatedArray = [...array];
    updatedArray[index].value = e.target.value;
    setArray(updatedArray);
  };

  const deleteRow = (id) => {
    const newArray = array.filter((item) => item.id !== id);
    setArray(newArray);
  };

  // Fonction pour gérer le clic du bouton
  const AddRow = () => {
    const lastItem = array[array.length - 1];
    setArray([...array, { id: lastItem ? lastItem.id + 1 : 1, title: 'nom de l item', value: 0 }]);
  };

  const createCSV = async () => {
    const newArray = array.map((item) => [item.title, item.value]);

    // Créer le contenu CSV en ajoutant des virgules et des sauts de ligne
    const csvContent = newArray.map((row) => row.join(',')).join('\n');

    await navigator.clipboard.writeText(csvContent);
  };

  const saveCollection = async () => {
    try {
      const ref = collection(firestore, 'ArrayItem');
      const querySnapshot = await getDocs(ref);
      const data = querySnapshot.docs;

      data.forEach(async (doc) => {
        console.log(doc.ref);
        await deleteDoc(doc.ref);
      });

      const test = collection(firestore, 'ArrayItem');

      array.forEach(async (item) => {
        await addDoc(test, { id: item.id, title: item.title, value: item.value });
      });
    } catch (error) {
      console.log(error);
    }
  };

  const inputArray = array.map((item, index) => (
    <div style={{ textAlign: 'center' }} key={index}>
      <br />

      <div className="flex flex-row items-center justify-center">
        <div className="basis-5/12 mr-5 ml-5"> 
          <div className="mt-2">
            <input
              type="text"
              name="first-name"
              id={`title-${index}`}
              value={item.title}
              placeholder="Mettre le nom de l'item"
              autoComplete="given-name"
              className="drop-shadow-xl block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 p-2 py-3 px-3"
              onChange={(e) => handleTitleChange(e, index)}
              
            />
          </div>
        </div>

        <div className="basis-5/12">
          <div className="mt-2">
            <input
              type="number"
              name="first-name"
              id={`title-${index}`}
              value={item.value}
              placeholder="Mettre le nombre d'item"
              autoComplete="given-name"
              className="drop-shadow-xl block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 p-5 py-3 px-3"
              onChange={(e) => handleNumberChange(e, index)}
              
            />
          </div>
        </div>
        <XMarkIcon className="h-8 w-8 mt-5 basis-1/12" aria-hidden="true" onClick={() => deleteRow(item.id)} />

      </div>   
    </div>
  ));

  

  return (
    <main className='App m-5'>

      {inputArray}
      
      <br />

      <div style={{textAlign: 'center'}}>
        <Button variant="outlined" color="primary" onClick={AddRow}>
          Ajouter une ligne
        </Button>
      </div>

      <br />

      <div style={{textAlign: 'center'}}>
        <Button variant="outlined" onClick={createCSV}>
          copy
        </Button>
      </div>

      <br />

      <div style={{textAlign: 'center'}}>
        <Button variant="outlined" onClick={saveCollection}>
          Sauvegarder la liste d'item
        </Button>
      </div>
    </main>
  );
}

export default Outils;


