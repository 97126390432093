import './App.scss';
import 'boxicons/css/boxicons.min.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Blank from './pages/Blank';
import Outils from './pages/Outils';
import Evenements from './pages/Evenements';
import Home from './pages/Home';

function App() {
    return (
        <BrowserRouter>
            <Routes>
                <Route path='/' element={<Home />}>
                    <Route index element={<Blank />} />
                    <Route path='outils' element={<Outils />} />
                    <Route path='evenements' element={<Evenements />} />
                    <Route path='user' element={<Blank />} />
                    <Route path='order' element={<Blank />} />
                </Route>
            </Routes>
        </BrowserRouter>

        
    );
}

export default App;
