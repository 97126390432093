// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import 'firebase/firestore';

import { getFirestore } from 'firebase/firestore';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyC20jBQTbguBLP9vuCtoaD-80cTKBaIK00",
  authDomain: "arknighttsuru.firebaseapp.com",
  projectId: "arknighttsuru",
  storageBucket: "arknighttsuru.appspot.com",
  messagingSenderId: "176580214714",
  appId: "1:176580214714:web:d4fef1dfe6772e0f146783"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export const firestore = getFirestore(app);